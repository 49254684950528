#card-header {
    display: grid;
    justify-content: center;
    grid-template-areas:
            'logo'
            'name'
            'data';
    background-color: lightgray;
    padding: 40px;
    grid-gap: 30px;
}

#card-logo {
    object-fit: contain;
    max-height: 200px;
    grid-area: logo;
    text-align: center;
    margin: auto;
    max-width: 85vw;
}

#card-name {
    grid-area: name;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#card-name img {
    height: 50px;
    margin: 0 20px 0 0;
}

#card-data {
    grid-area: ntw;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    min-width: 350px;
}

#card-ntw, #card-geography, #card-rptype {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#card-ntw img, #card-geography img, #card-rptype img {
    height: 65px;
    object-fit: contain;
    padding: 10px;
}

#card-data {
    grid-area: data;
}

.referrals, .empty-referrals {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-referrals {
    margin-top: 50px;
}

.disclaimer {
    font-size: small;
}

.other-referrals {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
}

.other-referrals-header {
    text-align: center;
}

.offer-description {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.offer-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.offer-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    box-shadow: 0 5px 10px gray;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    justify-content: space-around;
    margin-bottom: 40px;
}

.offer-detail-param, .offer-detail-value {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.offer-detail-param {
    font-weight: bold;
    font-size: large;
}

.offer-detail-value {
    font-size: large;
    background-color: lightgray;
}

.top-ref {
    margin: 0 15px;
}

.top-ref-header {
    margin: 40px 0 0 0;
}

.top-ref .ref-card {
    margin: 40px 0 50px 0;
}

.subheading {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

.ref-card {
    display: grid;
    grid-template-areas:
        'ref-card-username'
        'ref-card-offer'
        'ref-card-apply';
    border-radius: 5px;
    text-align: center;
    font-size: large;
    box-shadow: 0 5px 10px gray;
    overflow: hidden;

}

.ref-card-username {
    grid-area: ref-card-username;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ref-card-offer {
    grid-area: ref-card-offer;
    padding: 20px;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ref-card-offer-desc {
    margin-bottom: 10px;
}

.ref-card-offer-reward {
    font-weight: bold;
    font-size: x-large;
    margin-bottom: 10px;
}

.ref-card-offer-reward-desc {
    font-size: medium;
}

.ref-card-apply, .ref-card-apply-child, .ref-card-copy-link-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ref-card-apply {
    grid-area: ref-card-apply;
    padding: 20px;
    background-color: #343a40;
}

.ref-card-apply-child {
    font-size: larger;
    flex-direction: column;
}

.ref-card-copy-link-menu {
    flex-direction: column;
}

.ref-card-copy-link-label {
    font-size: large;
}

.ref-card-copy-link-username {
    font-weight: 600;
}

.ref-card-copy-link-content {
    margin: 15px auto;
    font-weight: 500;
    font-size: x-large;
}

.ref-link {
    box-shadow: 0 1px 5px gray;
    padding: 10px;
    color: black;
    margin: 20px 10px;
    font-size: large;
    border-radius: 5px;
}

.ref-link:hover {
    color: white;
    cursor: pointer;
    background-color: #343a40;
    text-decoration: none;
}

.ref-link-modal {
    box-shadow: none;
    overflow: hidden;
    border: none!important;
}

.ref-link-modal .ref-card {
    display: grid;
    grid-template-areas:
            'ref-card-username'
            'ref-card-offer'
            'ref-card-apply';
}

.offer-details-expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.offer-details-full {
    display: none;
}

@media only screen and (min-width: 768px) {
    #card-header {
        display: grid;
        justify-content: center;
        grid-template-areas:
                'logo name'
                'logo data';
        background-color: lightgray;
        padding: 40px;
        grid-auto-rows: 1fr;
        grid-gap: 0;
    }

    .ref-card {
        display: grid;
        grid-template-areas:
                'ref-card-username ref-card-apply'
                'ref-card-offer ref-card-apply';
        grid-auto-columns: 3fr 1.5fr;
    }

    #card-logo {
        margin-right: 40px;
    }

    .offer-details {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .offer-details-expand-button {
        display: none;
    }

    .offer-details-full {
        display: inherit;
    }
}

/* For very small mobile phones like the iPhone 5 */

@media only screen and (max-width: 350px) {
    #card-logo {
        height: 150px;
    }

    .offer-detail {
        margin: 20px;
    }

    #card-data {
        min-width: 270px;
    }
}
