/*
Table
 */

.table th {
    border-top: 2px solid #dee2e6;
}

.vertically-aligned-table-row td {
    vertical-align: middle;
}

/*
General
 */

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Avenir Next', sans-serif;
    margin-bottom: 90px;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #343a40;
    color: white;
}

button:focus {
    outline:0;
}

.centered-element {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.little-spaced-element {
    margin: 5px;
}

.spaced-element {
    margin: 15px;
}

.lot-spaced-element {
    margin: 25px;
}

.bottom-spaced-element {
    margin-bottom: 15px;
}

.element-with-sharp-corners {
    border-radius: 0;
}

.filter-input-group {
    margin: 15px;
    width: 350px;
}

.page-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.plain-white-text {
    color: white;
    text-decoration: none;
}

.plain-white-text:hover {
    color: white;
}

.plain-black-text {
    color: black;
    text-decoration: none;
}

.plain-black-text:hover {
    color: black;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.box-text {
    text-decoration: none;
    border: 2px solid #343a40;
    padding: 10px;
    color: black;
    margin: 10px;
}

.box-text-hover:hover {
    background-color: lightgray;
    color: black;
}

.separated-text {
    display: flex;
    align-items: center;
    text-align: center;
}

.separated-text::before, .separated-text::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}

.separated-text::before {
    margin-right: 1.5em;
}

.separated-text::after {
    margin-left: 1.5em;
}

.nav-link {
    color: white;
    text-decoration: none;
}

.nav-link:hover {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}
