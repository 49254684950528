.ref-actions {
    display: flex;
    flex-direction: column;
    border: none;
}

.ref-actions button {
    margin-bottom: 10px;
}

#card-offer-chooser select {
    max-width: 100%;
}

@media only screen and (min-width: 768px) {
    .ref-actions {
        flex-direction: row;
    }

    .ref-actions button {
        margin-bottom: 0;
        margin-right: 10px;
    }
}
