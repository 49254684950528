.user-ref-stat, .card-ref-stat {
    box-shadow: 0 1px 5px gray;
    padding: 10px;
    color: black;
    margin: 20px 10px;
    font-size: large;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.user-ref-stat:hover, .card-ref-stat:hover {
    color: white;
    background-color: #343a40;
    text-decoration: none;
}

.card-ref-stat:hover {
    cursor: pointer;
}

.ref-stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
}
