.faq-entry {
    display: flex;
    flex-direction: column;
    margin: 15px 0 35px 0;
    font-size: large;
}

.faq-question {
    font-weight: 500;
    margin-bottom: 10px;
}

.faq-answer {
    white-space: pre-wrap;
}