.card-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.card-logo {
    object-fit: contain;
    height: 160px;
}

.card-label {
    height: 30px;
    font-size: medium;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.card-geo-logo {
    height: 30px;
    margin-right: 15px;
}

.search-controls {
    border-top: 1px solid #343a40;
    display: flex;
    padding: 10px;
    background-color: lightgray;
    justify-content: center;
    flex-direction: column;
}

.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    padding: 0 50px 0 50px;
    flex-direction: column;
}

.search-bar-ref-selectors {
    display: flex;
    justify-content: center;
}

.search-bar-ref-selector {
    padding: 5px;
}

.search-bar-ref-selector input {
    margin-right: 5px;
}

.search-bar-ref-selector label {
    font-size: medium;
}

@media only screen and (min-width: 768px) {
    .search-controls {
        display: flex;
        padding: 10px;
        background-color: lightgray;
        justify-content: center;
        flex-direction: row;
    }

    .search-bar {
        justify-content: space-between;
        flex-direction: row;
        padding: 0 50px 0 50px;
    }

    .search-bar-ref-selectors {
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }

    .search-bar-ref-selector {
        margin-right: 20px;
    }

    .search-bar-ref-selector label {
        margin-bottom: 0;
    }

    .card-logo {
        height: 210px;
    }

    .card-geo-logo {
        height: 40px;
        margin-right: 15px;
    }
}

.search-control {
    display: flex;
    margin: 20px;
}

/* For very small mobile phones like the iPhone 5 */

@media only screen and (max-width: 350px) {
    .search-bar-ref-selectors {
        justify-content: center;
    }
}
